import React from 'react';
import { styled } from 'linaria/react';
import { InlineImage } from '../../ui/InlineImage';
import Image from '@jetshop/ui/Image/Image';
import { convertPropsToObject } from '../ContentRender';
import { theme } from '../../Theming/Theming';
import { ContentHtml } from '../ContentText';
import { ContentLink } from '../ContentLink';
import { Below, Above } from '@jetshop/ui/Breakpoints';

const ImageComponentWrapper = styled.div`
  .image-component-inner {
    position: relative;
    &.box-right,
    &.box-left {
      display: flex;
      flex-wrap: wrap;
      > * {
        width: 100%;
        &.image-text {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          ${theme.below.sm} {
            height: 100%;
            justify-content: space-between;
          }
        }
        ${theme.above.md} {
          &.image-wrapper:not(.long-text) {
            width: 60%;
          }
          &.image-text:not(.long-text) {
            width: 40%;
          }
        }
        ${theme.above.lg} {
          &.image-wrapper.long-text {
            width: 50%;
          }
          &.image-text.long-text {
            width: 50%;
          }
        }
      }
    }
    &.box-left {
      ${theme.above.md} {
        .image-wrapper {
          order: 1;
        }
      }
    }
    &.no-media {
      height: 100%;
    }
  }
`;

const ImageWrapper = styled('div')`
  display: block;
  overflow: hidden;
  &.circle {
    background: white;
    border-radius: 50%;
    box-shadow: 0px 15px 0px ${theme.colors.primary};
    margin-bottom: 15px;
  }
`;
const VideoWrapper = styled('div')`
  position: relative;
  video {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
  ${theme.below.sm} {
    &.one-column {
      padding-top: 90% !important;
    }
  }
`;
const TextContent = styled(ContentHtml)`
  &:not(.no-media) {
    position: absolute;
    z-index: 2;
    padding: 5%;

    > *:last-child {
      margin-bottom: 0 !important;
    }

    //POSIITONS
    &.top-left,
    &.top-center,
    &.top-right {
      top: 0;
    }
    &.middle-left,
    &.middle-center,
    &.middle-right {
      top: 50%;
    }
    &.bottom-left,
    &.bottom-center,
    &.bottom-right {
      bottom: 0;
    }

    &.top-left,
    &.middle-left,
    &.bottom-left {
      left: 0;
    }
    &.top-center,
    &.middle-center,
    &.bottom-center {
      text-align: center;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &.top-right,
    &.middle-right,
    &.bottom-right {
      text-align: right;
      left: 30%;
      right: 0;
    }

    &.middle-left,
    &.middle-right {
      transform: translateY(-50%);
    }

    &.middle-center {
      transform: translateX(-50%) translateY(-50%);
    }

    &.full-left,
    &.full-center,
    &.full-right {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }
    &.full-left {
      align-items: flex-start;
    }
    &.full-center {
      align-items: center;
      text-align: center;
    }
    &.full-right {
      align-items: flex-end;
    }

    &.under {
      text-align: center;
      position: static;
      padding-bottom: 10%;
      &.light {
        background: ${theme.colors.backgroundDark};
      }
    }

    &.box-left,
    &.box-right {
      text-align: center;
      position: static;
      background: ${theme.colors.backgroundLight};
      &.light {
        background: ${theme.colors.backgroundDark};
      }
    }
    &.box-overlay {
      background: ${theme.colors.backgroundLight};
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;
      ${theme.below.md} {
        min-width: 80%;
      }
      border-radius: 30px;
    }
  }
  &.no-media {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: ${theme.startPage.row.noImage.backgroundLight};
    button {
      max-width: 300px;
    }
    &.light {
      background: ${theme.startPage.row.noImage.backgroundDark};
    }
    &.top-left,
    &.top-center,
    &.top-right {
      justify-content: flex-start;
    }
    &.middle-left,
    &.middle-center,
    &.middle-right {
      justify-content: center;
    }
    &.bottom-left,
    &.bottom-center,
    &.bottom-right {
      justify-content: flex-end;
    }

    &.top-left,
    &.middle-left,
    &.bottom-left {
      align-items: flex-start;
      text-align: left;
    }
    &.top-center,
    &.middle-center,
    &.bottom-center {
      text-align: center;
      align-items: center;
    }
    &.top-right,
    &.middle-right,
    &.bottom-right {
      align-items: flex-end;
      text-align: right;
    }

    &.middle-left,
    &.middle-right {
    }

    &.middle-center {
    }
  }
`;

function getSizes(columns, look, index) {
  function getStandardSizes(columns) {
    switch (columns) {
      case 1:
        return [1, 1, 1, 1];
      case 2:
        return [1, 1, 1 / 2, 1 / 2];
      case 3:
        return [1, 1, 1 / 2, 1 / 3];
      case 4:
        return [1, 1 / 2, 1 / 2, 1 / 4];
      case 5:
        return [1, 1 / 2, 1 / 2, 1 / 5];
      case 6:
        return [1, 1 / 3, 1 / 4, 1 / 6];
      default:
        return [1, 1 / 2, 1 / 2, 1 / 4];
    }
  }

  //SPECIAL IMAGE SIZES FOR MOSAIC LOOK
  return look === 'mosaic'
    ? index === 2
      ? [1, 1, 1, 2 / 3]
      : [1 / 2, 1 / 2, 1 / 2, 1 / 3]
    : getStandardSizes(columns);
}

const Video = ({ aspect, videoUrl, columns }) => {
  const aspectData = aspect.split(':');
  const padding = (aspectData[1] / aspectData[0]) * 100;
  return (
    <VideoWrapper
      data-flight-image-container
      style={{ paddingBottom: padding + '%' }}
      className={columns === 1 ? 'one-column' : ''}
    >
      <video autoPlay playsInline muted loop>
        <source src={videoUrl} />
        Your browser does not support the video tag.
      </video>
    </VideoWrapper>
  );
};

const ImageComponentInner = ({
  props,
  aspect,
  critical,
  look,
  columns,
  aboveLG,
  belowMD,
  index,
}) => {
  const imageUrl = props.imageUrl && props.imageUrl.value;
  const imageMobileUrl = props.imageMobileUrl && props.imageMobileUrl.value;
  const videoUrl = props.videoUrl && props.videoUrl.value;
  const text = props.text.value;
  const textPosition = props.textPosition.value || 'middle-center';
  const textColor = (props.textColor && props.textColor.value) || 'dark';
  const noMedia = imageUrl || videoUrl;
  const isCircle = aspect === 'circle';
  const crop = !isCircle;
  const longText = text && text.length > 300;

  //IMAGE SIZES
  const imageSizes = getSizes(columns, look, index);

  //IMAGE ASPECTS
  const imageAspect =
    isCircle ||
    (belowMD && columns === 1) ||
    (belowMD && look === 'text-over-2-images')
      ? '1:1'
      : aboveLG && look === 'mosaic' && index === 2
      ? '3:4'
      : aspect;

  //DESKTOP IMAGE OR MOBILE
  const isMobileImage = belowMD && imageMobileUrl;
  const image = isMobileImage ? imageMobileUrl : imageUrl;

  return (
    <div
      className={`image-component-inner ${textPosition} ${
        noMedia ? '' : 'no-media'
      }`}
    >
      {(imageUrl || isMobileImage) && (
        <ImageWrapper
          className={`image-wrapper ${isCircle ? 'circle' : ''} ${
            longText && 'long-text'
          }`}
        >
          {aspect === 'inline' ? (
            <InlineImage sizes={imageSizes} src={image} alt={imageUrl} />
          ) : (
            <Image
              critical={critical}
              className="image-holder"
              aspect={imageAspect}
              sizes={imageSizes}
              src={image}
              alt={image}
              crop={crop || true}
              cover={crop}
            />
          )}
        </ImageWrapper>
      )}
      {!imageUrl && !isMobileImage && videoUrl && (
        <ImageWrapper
          className={`image-wrapper ${isCircle ? 'circle' : ''} ${
            longText && 'long-text'
          }`}
        >
          <Video aspect={imageAspect} videoUrl={videoUrl} columns={columns} />
        </ImageWrapper>
      )}
      <TextContent
        className={`image-text ${textPosition} ${textColor} ${
          longText && 'long-text'
        } ${noMedia ? '' : 'no-media'}`}
        content={text}
      />
    </div>
  );
};

const ImageComponent = ({
  data,
  columns,
  aspect = '1:1',
  critical = false,
  look,
  index,
}) => {
  const props = convertPropsToObject(data);
  const link = props.link.value;
  return (
    <Above breakpoint="lg">
      {(aboveLG) => (
        <Below breakpoint="md">
          {(belowMD) => {
            const imageProps = {
              props,
              aspect,
              critical,
              look,
              columns,
              aboveLG,
              belowMD,
              index,
            };
            return (
              <ImageComponentWrapper className={`image-component-wrapper`}>
                {link ? (
                  <ContentLink to={link}>
                    <ImageComponentInner {...imageProps} />
                  </ContentLink>
                ) : (
                  <ImageComponentInner {...imageProps} />
                )}
              </ImageComponentWrapper>
            );
          }}
        </Below>
      )}
    </Above>
  );
};

export default ImageComponent;
