import React from 'react';
import SiteContentContext from './SiteContentContext';
import { SiteContentHtmlParser } from './SiteContentHtmlParser';
import { useQuery } from 'react-apollo';
import siteContentQuery from './siteContentQuery.gql';
import { config } from '../../shop.config.custom';
import { convertPropsToObject } from '../ContentRender/ContentRender';

export const convertPropsToSiteContentObject = (item) => {
  const props = item.properties.reduce((merged, current) => {
    const propValue = current.value && current.value.value;
    merged[current.name] =
      current.type === 'html' ? (
        <SiteContentHtmlParser content={propValue} />
      ) : current.type === 'dropdown' && current.name.search('show') !== -1 ? (
        propValue === 'yes' ? (
          true
        ) : (
          false
        )
      ) : (
        propValue
      );
    return merged;
  }, {});
  return props;
};

const SiteContent = ({ children }) => {
  const result = useQuery(siteContentQuery, {
    variables: { id: config.settings.siteContentId },
  });

  const startPage = result?.data?.startPage;
  const items = startPage?.data?.items;

  const siteContentObject =
    items && items.filter((item) => item.type === 'site-content')[0];

  const otherObjects =
    items && items.filter((item) => item.type !== 'site-content');

  const blogObject =
    items && items.filter((item) => item.type === 'category-blogs')[0];

  const props =
    siteContentObject && convertPropsToSiteContentObject(siteContentObject);

  const blogProps = blogObject?.children.map((item) =>
    convertPropsToObject(item)
  );

  return (
    <SiteContentContext.Provider
      value={{ ...props, rest: otherObjects, blogs: blogProps }}
    >
      {children}
    </SiteContentContext.Provider>
  );
};

export default SiteContent;
