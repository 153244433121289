import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { styled } from 'linaria/react';
import { GridPrice } from '../Price';
import t from '@jetshop/intl';
import { Favourite } from '../ProductList/Favourite';
import { theme } from '../Theming/Theming';
import AddToCart from '../Cart/AddToCart';
import { ButtonUI } from '../ui/ButtonUI';

export const ProductCardInner = styled('div')`
  padding: ${theme.productGrid.product.padding.sm};
  position: relative;
  line-height: 1;
  min-width: 0;
  ${theme.above.md} {
    padding: ${theme.productGrid.product.padding.md};
  }
  ${theme.above.xl} {
    padding: ${theme.productGrid.product.padding.lg};
  }
  > button {
    width: 100%;
  }
  .product-card-background {
    background: ${theme.productGrid.product.background};
    padding: 4px;
    ${theme.above.md} {
      padding: 15px;
    }
    border-radius: 25px;
    > a {
      color: inherit;
      display: block;
    }
  }
`;

export const Details = styled('div')`
  margin: 1rem 0 0.5rem;
  position: relative;
`;

export const DetailsInner = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-end;
  > * {
    flex-grow: 0;
    width: 100%;
  }
  > button {
    margin-top: 1rem;
  }
  text-align: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
`;

export const BadgeWrapper = styled('div')`
  color: white;
  text-align: center;
  font-size: 0.9rem;
  img {
    width: auto;
    height: auto;
    max-width: 70px;
    ${theme.below.md} {
      max-width: 50px;
    }
  }
`;

export const Name = styled('h4')`
  margin: 0 0 0.3rem 0 !important;
  padding: 0 1.5rem;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.5;
  font-family: ${theme.productGrid.product.name.fontFamily} !important;
  font-weight: ${theme.productGrid.product.name.fontWeight} !important;
  height: 41px;
  overflow: hidden;
`;
export const SubName = styled('p')`
  margin: 0 0 0.7rem 0;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${theme.below.md} {
    font-size: 0.9rem;
  }
`;
export const ListPrice = styled(GridPrice)`
  [data-flight-price] {
    justify-content: center;
  }
`;

const ProductCardComponent = ({
  product,
  imageAspect,
  imageSizes,
  children,
  showFavorite,
  loadImageEagerly,
  categoryPath,
  quickBuy,
  ...rest
}) => {
  //HAS IMAGES
  const hasImages = product.images && product.images.length > 0;

  //STANDARD PRODUCT IMAGE
  const images = product?.images;

  let badges = [...product.badges];

  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge',
    });

  return (
    <ProductCardInner className="product-card-inner">
      <div className="product-card-background">
        <ProductLink product={product} categoryPath={categoryPath} {...rest}>
          {hasImages ? (
            <ImageWrapper>
              <Image
                sizes={imageSizes}
                aspect={imageAspect}
                alt={images[0].alt}
                src={images[0].url}
                modifiedDate={images[0].modifiedDate}
                critical={loadImageEagerly}
              />
              <BadgeWrapper>
                <Badges badges={product.badges} />
              </BadgeWrapper>
            </ImageWrapper>
          ) : (
            <Image src={transparentDataImg} aspect={imageAspect} />
          )}
        </ProductLink>
        <Details className="product-card-detail">
          {showFavorite && (
            <Favourite
              product={product}
              style={{
                position: 'absolute',
              }}
            />
          )}
          <DetailsInner>
            <header>
              <ProductLink product={product} categoryPath={categoryPath}>
                <Name>{product.name}</Name>
              </ProductLink>
              <SubName className="sub-name">{product.subName}</SubName>
            </header>
            <ListPrice
              hidePrice={product.hidePrice}
              price={product.price}
              previousPrice={product.previousPrice}
            />
            {quickBuy && (
              <>
                {!product.hasVariants &&
                product.stockStatus &&
                product.stockStatus.buyable ? (
                  <AddToCart
                    product={product}
                    quantity={1}
                    selectedVariation={product}
                  >
                    {(add) => (
                      <ButtonUI
                        className="list"
                        onClick={() => {
                          add();
                        }}
                      >
                        {t('Buy')}
                      </ButtonUI>
                    )}
                  </AddToCart>
                ) : (
                  <ButtonUI className="list">
                    <ProductLink product={product}>{t('Go to')}</ProductLink>
                  </ButtonUI>
                )}
              </>
            )}
          </DetailsInner>
        </Details>
        {children}
      </div>
    </ProductCardInner>
  );
};

export function ProductCard({
  product,
  className,
  imageAspect = theme.productGrid.product.imageAspect,
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'div',
  children,
  showFavorite = true,
  showVariants = true,
  loadImageEagerly = false,
  quickBuy = true,
  ...linkProps
}) {
  const Tag = as;

  //PROPS TO PASS TO PRODUCTCARD COMP
  const props = {
    imageAspect,
    imageSizes,
    children,
    showFavorite,
    loadImageEagerly,
    quickBuy,
    ...linkProps,
  };
  return (
    <Tag
      className={`product-card ${className}`}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductCardComponent product={product} {...props} />
    </Tag>
  );
}
