import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import Image from '@jetshop/ui/Image';
import NewsletterBox from './Newsletter/NewsletterBox';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';

const FooterWrapper = styled('footer')`
  background: rgb(233, 92, 153);
  background: linear-gradient(
    0deg,
    rgba(233, 92, 153, 1) 0%,
    rgba(250, 173, 206, 1) 100%
  );
  z-index: 2;
  a,
  a:active {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-payment {
    border-top: 5px solid white;
    border-bottom: 5px solid white;
    padding: 1rem 1rem;
    > div {
      max-width: 600px;
      margin: 0 auto;
    }
  }
  .footer-content {
    padding: 3rem 0 0;
    color: ${theme.footer.color};
  }
`;

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 1rem;
  padding-top: 0.5rem;
  margin-bottom: 3rem;
  ${theme.below.sm} {
    flex-direction: column;
  }
  > div {
    width: 100%;
    padding: 2rem 0;
    ${theme.only.md} {
      width: 40%;
      text-align: center;
    }
    ${theme.above.lg} {
      width: 20%;
      padding: 3rem 1rem;
    }
    > h3 {
      margin-bottom: 1em !important;
    }
    > ul {
      > li {
        line-height: 2em;
      }
    }
    p {
      line-height: 1.7em;
      margin-bottom: 1rem;
    }
  }
`;

export const FooterLogo = styled('div')`
  > div {
    width: 100%;
    max-width: 400px;
  }
`;

const Footer = () => {
  const { footer1, footer2, footer3, footer4, footerLogo, paymentLogo } =
    useContext(SiteContentContext);
  return (
    <>
      <FooterWrapper>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>
        {footerLogo && (
          <div className="footer-payment">
            <Image aspect="12:1" src={paymentLogo} sizes={[1, 1, 1, 500]} />
          </div>
        )}
        <div className="footer-content">
          <MaxWidth>
            <FooterContentWrapper>
              {footerLogo && (
                <FooterLogo>
                  <div>
                    <Image aspect="3:1" src={footerLogo} sizes={[300]} />
                  </div>
                </FooterLogo>
              )}
              <div>{footer1}</div>
              <div>{footer2}</div>
              <div>{footer3}</div>
              <div>{footer4}</div>
            </FooterContentWrapper>
          </MaxWidth>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
