import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { Link } from 'react-router-dom';
import MaxWidth from '../../Layout/MaxWidth';
import { calcSpace } from '../../CategoryPage/ProductGrid';
import { RowHeader } from './RowHeader';

const ButtonsWrapper = styled.div`
  margin: 3rem 0;
  ${theme.below.sm} {
    margin: 2rem 0;
  }
  .row-header {
    margin: 1rem 0 1rem 0;
  }
  .button-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > button {
      ${theme.only.sm} {
        ${calcSpace(2, 1)};
      }
      ${theme.only.md} {
        ${calcSpace(2, 1)};
      }
    }
  }
  a {
    font-size: 1.1rem;
    margin: 0.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.5rem;
    color: ${theme.startPage.cta.color};
    background: ${theme.startPage.cta.background};
    box-shadow: 0px 0px 13px ${theme.startPage.cta.background};
    border-radius: 50px;
    ${theme.below.md} {
      margin: 1%;
    }
  }
`;

const ButtonComponent = ({ data }) => {
  const props = convertPropsToObject(data);
  const name = props.name?.value;
  const link = props.link?.value;
  if (!name || !link) return null;
  return <Link to={link}>{name}</Link>;
};

const Buttons = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const columns = data.children.length;

  if (data && data.children)
    return (
      <ButtonsWrapper data-index={index}>
        <MaxWidth>
          <RowHeader props={props} />
          <div className="button-list" data-columns={columns}>
            {data.children.map((child, index) => (
              <ButtonComponent data={child} key={index} />
            ))}
          </div>
        </MaxWidth>
      </ButtonsWrapper>
    );
  else return null;
};

export default Buttons;
